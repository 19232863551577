// extracted by mini-css-extract-plugin
export var audioLabel = "PromotionalstylesA-module--audioLabel--091c8";
export var audioPlayer = "PromotionalstylesA-module--audioPlayer--fcb8d";
export var closeButton = "PromotionalstylesA-module--closeButton--aa5ab";
export var contentBody = "PromotionalstylesA-module--contentBody--512a9";
export var contentBox = "PromotionalstylesA-module--contentBox--22a20";
export var contentWrapper = "PromotionalstylesA-module--contentWrapper--37ef4";
export var cta = "PromotionalstylesA-module--cta--5d282";
export var ctaContainer = "PromotionalstylesA-module--ctaContainer--60ec4";
export var headline = "PromotionalstylesA-module--headline--e6762";
export var image = "PromotionalstylesA-module--image--3a5a5";
export var infoItems = "PromotionalstylesA-module--infoItems--f7e8a";
export var leftAlignBody = "PromotionalstylesA-module--leftAlignBody--71547";
export var modal = "PromotionalstylesA-module--modal--20646";
export var modalPreviewPlayIcon = "PromotionalstylesA-module--modalPreviewPlayIcon--9868d";
export var name = "PromotionalstylesA-module--name--d9a74";
export var overlay = "PromotionalstylesA-module--overlay--f9f68";
export var paragraph = "PromotionalstylesA-module--paragraph--c0d4b";
export var playArrow = "PromotionalstylesA-module--playArrow--3d257";
export var playerWrapper = "PromotionalstylesA-module--playerWrapper--4b792";
export var primaryimage = "PromotionalstylesA-module--primaryimage--7cea3";
export var reactPlayer = "PromotionalstylesA-module--reactPlayer--2ea47";
export var rightAlignBody = "PromotionalstylesA-module--rightAlignBody--da402";
export var secondaryRight = "PromotionalstylesA-module--secondaryRight--58e2a";
export var videoWrapper = "PromotionalstylesA-module--videoWrapper--a19f2";