import React, { useState, lazy } from 'react';
import loadable from '@loadable/component';
const ReactPlayer = loadable(() => import('react-player'));
import Modal from 'react-modal';
import ArrowLgButton from './../Global/ArrowLgButton';
import AudioIcon from '../Global/AudioIcon';
import CloseButton from '../Global/CloseButton';
import CtaLink from '../Global/CtaLink';
import RichText from '../Global/RichText';
//@ts-ignore
import * as styles from './PromotionalstylesA.module.css';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import { graphql } from 'gatsby';
import {
    KontentStringValue,
    KontentRadioMultiChoice,
    KontentMedia,
    BrandColor,
    CTALocation,
    KontentNumberValue,
} from '../../../types/KontentProps';
import { getColor, getMediaData, getMultiChoice } from '../Global/DataUtils';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { getFluidImageData } from '../Global/DataUtils/gatsbyImageUtils';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PlayArrow from '../Global/PlayArrow';

type WindowAction = 'New Window' | 'Same Window';
type ImagePositionType = 'right' | 'left' | undefined;

interface videoOrAudiomedia {
    value: {
        url: string;
    }[];
}
interface videoOrAudioFileLocationmedia {
    value: {
        codename: string;
    }[];
}
interface Props extends KontentNumberValue {
    attribution_certification: KontentStringValue;
    attribution_name: KontentStringValue;
    component_anchor_name: KontentStringValue;
    content_panel__content_panel_color: BrandColor;
    content_panel__content_panel_font_color: BrandColor;
    cta_configuration__cta_label: KontentStringValue;
    cta_configuration__cta_location: CTALocation;
    cta_configuration__cta_location_anchor_point: KontentStringValue;
    cta_configuration__cta_window_action: KontentRadioMultiChoice<WindowAction>;
    cta_type: videoOrAudioFileLocationmedia;
    headline: KontentStringValue;
    icon_for_cta_button: KontentMedia;
    paragraph: KontentStringValue;
    primary_image: KontentMedia;
    primary_image_alignment?: KontentRadioMultiChoice<ImagePositionType>;
    secondary_image: KontentMedia;
    secondary_image_alignment?: KontentRadioMultiChoice<ImagePositionType>;
    video_or_audioIcono_file: KontentMedia;
    video_or_audio_file_location: videoOrAudioFileLocationmedia;
    video_or_audio_link: KontentStringValue;
    video_or_audio_file?: videoOrAudiomedia;
}

const PromotionalstylesA: React.FC<Props> = ({
    attribution_certification,
    attribution_name,
    component_anchor_name,
    content_panel__content_panel_color,
    content_panel__content_panel_font_color,
    cta_configuration__cta_label,
    cta_configuration__cta_location,
    cta_configuration__cta_window_action,
    cta_type,
    headline,
    icon_for_cta_button,
    paragraph,
    primary_image,
    primary_image_alignment,
    secondary_image,
    secondary_image_alignment,
    video_or_audio_file,
    video_or_audio_file_location,
    video_or_audio_link,
    cta_configuration__cta_location_anchor_point,
}) => {
    const authImageUrl = getMediaData(secondary_image);
    const [showControl, setShowControl] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const primaryImage = getFluidImageData(primary_image);
    const videoLink = video_or_audio_link.value;
    const videoLabel = getMediaData(icon_for_cta_button);
    const imagePosition = getMultiChoice(primary_image_alignment);
    const breakpoints = useBreakpoint();
    const isMobile = !breakpoints.sm;
    const bodyAlignmentClass =
        imagePosition === 'right'
            ? styles.rightAlignBody
            : styles.leftAlignBody;
    const secondaryImagePosition = getMultiChoice(secondary_image_alignment);

    const buttonType = cta_type.value[0]?.codename;
    const textBackgroundColor = getColor(content_panel__content_panel_color);
    const textBackgroundfontColor = getColor(
        content_panel__content_panel_font_color
    );
    const videoOrAudioFileUrl =
        video_or_audio_file?.value.length !== 0
            ? video_or_audio_file?.value[0]?.url
            : '';

    const audioUrl =
        video_or_audio_file_location.value[0]?.codename === 'link'
            ? video_or_audio_link.value
            : videoOrAudioFileUrl;
    const handleClick = () => {
        video_or_audio_file_location.value[0]?.codename === 'attached'
            ? setIsOpen(true)
            : window.open(videoLink, '_blank');
    };
    const handleCloseBtnClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setIsOpen(false);
    };
    const TextColor = textBackgroundfontColor
        ? textBackgroundfontColor
        : undefined;
    const BackgroundColor = textBackgroundColor
        ? textBackgroundColor
        : undefined;
    const anchorValue = component_anchor_name?.value;
    return (
        <div className={styles.contentWrapper} id={anchorValue || undefined}>
            <div className={styles.contentBody + ' ' + bodyAlignmentClass}>
                <div className={styles.videoWrapper}>
                    <div className={styles.modal}>
                        <GatsbyImage
                            //@ts-ignore
                            fluid={primaryImage?.source}
                            alt={primaryImage?.alt}
                            className={styles.primaryimage}
                        />
                        {buttonType === 'video_play' && (
                            <div
                                className={styles.playArrow}
                                onClick={handleClick}
                            >
                                <PlayArrow
                                    heightWidth="4rem"
                                    strokeColor="#ffffff"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={styles.contentBox}
                    style={{
                        backgroundColor: BackgroundColor,
                        color: TextColor,
                    }}
                >
                    {authImageUrl && (
                        <div
                            className={
                                secondaryImagePosition === 'right'
                                    ? styles.image + ' ' + styles.secondaryRight
                                    : styles.image
                            }
                        >
                            <img
                                src={authImageUrl?.url}
                                alt={authImageUrl?.description}
                            />
                        </div>
                    )}

                    <div className={styles.infoItems}>
                        {headline?.value && (
                            <div className={styles.headline}>
                                <h3>{headline?.value}</h3>
                            </div>
                        )}
                        {paragraph?.value !== '<p><br></p>' && (
                            <div className={styles.paragraph}>
                                {paragraph && RichText(paragraph)}
                            </div>
                        )}
                        {buttonType === 'standard_button' && (
                            <div className={styles.ctaContainer}>
                                <CtaLink
                                    href={
                                        cta_configuration__cta_location_anchor_point?.value
                                            ? BuildLinkHref(
                                                  cta_configuration__cta_location
                                              ) +
                                              '#' +
                                              cta_configuration__cta_location_anchor_point?.value
                                            : BuildLinkHref(
                                                  cta_configuration__cta_location
                                              )
                                    }
                                    target={
                                        getMultiChoice(
                                            cta_configuration__cta_window_action
                                        ) === 'new_window'
                                            ? '_blank'
                                            : undefined
                                    }
                                    visualStyle="outlineLight"
                                    color={TextColor}
                                >
                                    {cta_configuration__cta_label?.value}
                                </CtaLink>
                            </div>
                        )}
                        {buttonType === 'audio_file' && (
                            <div
                                className={styles.ctaContainer}
                                onClick={() => {
                                    setShowControl(true);
                                }}
                            >
                                <CtaLink
                                    target={
                                        getMultiChoice(
                                            cta_configuration__cta_window_action
                                        ) === 'new_window'
                                            ? '_blank'
                                            : undefined
                                    }
                                    visualStyle="outlineLight"
                                    color={TextColor}
                                >
                                    {showControl ? (
                                        <div>
                                            <audio
                                                controls
                                                autoPlay
                                                className={styles.audioPlayer}
                                            >
                                                <source
                                                    src={audioUrl}
                                                    type="audio/mpeg"
                                                ></source>
                                            </audio>
                                        </div>
                                    ) : (
                                        <div className={styles.audioLabel}>
                                            <div>
                                                {
                                                    cta_configuration__cta_label?.value
                                                }
                                            </div>
                                            <div style={{ width: 30 }}>
                                                <AudioIcon strokeColor="black"></AudioIcon>
                                            </div>
                                        </div>
                                    )}
                                </CtaLink>
                            </div>
                        )}
                        {attribution_name?.value && (
                            <div className={styles.name}>
                                {attribution_name?.value}
                            </div>
                        )}
                        {attribution_certification?.value && (
                            <div>{attribution_certification?.value}</div>
                        )}
                    </div>
                </div>
                {video_or_audio_file_location.value[0]?.codename ===
                    'attached' && (
                    <div>
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={() => {
                                setIsOpen(false);
                            }}
                            shouldCloseOnOverlayClick={true}
                            overlayClassName={styles.overlay}
                            style={{
                                content: {
                                    maxWidth: '1200px',
                                    margin: '0 auto',
                                    inset: isMobile
                                        ? '30vh 15px auto'
                                        : '136px 30px 30px',
                                    overflow: 'hidden',
                                    padding: breakpoints.l
                                        ? '20px 15px 20px'
                                        : '20px 15px 3rem',
                                    borderRadius: '1rem',
                                },
                            }}
                        >
                            <div className={styles.closeButton}>
                                <CloseButton
                                    visualStyle="orange"
                                    onClick={handleCloseBtnClick}
                                />
                            </div>
                            <ReactPlayer
                                className={styles.reactPlayer}
                                controls={true}
                                playing={true}
                                width="100%"
                                height={isMobile ? '45vw' : '100%'}
                                previewTabIndex={1}
                                url={
                                    video_or_audio_file?.value[0]?.url
                                        ? video_or_audio_file?.value[0]?.url
                                        : video_or_audio_link?.value
                                }
                            />
                        </Modal>
                    </div>
                )}
            </div>
        </div>
    );
};
export default PromotionalstylesA;

export const fragmentPromotionalStyleA = graphql`
    fragment PromotionalStyleA on kontent_item_component___promotional_style_a {
        elements {
            attribution_certification {
                value
            }
            attribution_name {
                value
            }
            component_anchor_name {
                value
            }
            content_panel__content_panel_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            content_panel__content_panel_font_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            cta_type {
                value {
                    codename
                }
            }
            headline {
                value
            }
            icon_for_cta_button {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            icon_svg_file {
                                value {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            paragraph {
                value
            }
            primary_image {
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            file {
                                value {
                                    url
                                    description
                                    name
                                    fluid(maxWidth: 1105, quality: 100) {
                                        ...KontentAssetFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
            primary_image_alignment {
                value {
                    codename
                }
            }
            secondary_image {
                value {
                    ... on kontent_item_media___image {
                        id
                        elements {
                            file {
                                value {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            secondary_image_alignment {
                value {
                    codename
                }
            }
            video_or_audio_file {
                value {
                    url
                }
            }
            video_or_audio_file_location {
                value {
                    codename
                }
            }
            video_or_audio_link {
                value
            }
        }
    }
`;
